import iFrameParams from '../data/iFrameParams'
const initApplePay = () => {
  let applePayAvailable
  let canPayment

  console.log('<==starting Apple pay availability check==>')

  try {
    applePayAvailable = window.ApplePaySession
    try {
      canPayment = window?.ApplePaySession?.canMakePayments()
    } catch (error) {
      console.log('ApplePay can`t make payment', error)
    }
  } catch (error) {
    console.log('ApplePay availability check failed', error)
  }

  if (!applePayAvailable || !canPayment) return
  console.log('<==finished Apple pay availability check==>')
  const scriptApplePay = document.createElement('script')
  const scriptApplePayBtn = document.createElement('script')

  scriptApplePay.type = 'text/javascript'
  scriptApplePayBtn.type = 'text/javascript'
  scriptApplePayBtn.crossOrigin = 'anonymous'
  scriptApplePayBtn.async = true
  scriptApplePay.setAttribute('src', 'https://cdn.safecharge.com/safecharge_resources/v1/sc_api_applepay.min.js')
  scriptApplePayBtn.setAttribute('src', 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js')
  document.body.appendChild(scriptApplePay)
  document.body.appendChild(scriptApplePayBtn)
}

export const createAndAppendBtn = (
  paymentBlock: HTMLElement,
  applePaySession: (e: Event) => void,
  availableApplePay: boolean
) => {
  const { theme } = iFrameParams
  const applePayBtnParentCreate = document.createElement('div')
  const applePayBtnCreate = document.createElement('apple-pay-button')

  applePayBtnParentCreate.setAttribute('class', 'applePay-wrap')
  applePayBtnParentCreate.setAttribute('id', 'applePay-wrap')

  applePayBtnCreate.setAttribute('id', 'apple-pay-button')
  applePayBtnCreate.setAttribute('buttonstyle', theme === 'light' ? 'black' : 'white')
  applePayBtnCreate.setAttribute('type', 'plain')
  applePayBtnCreate.setAttribute('locale', 'en-US')
  applePayBtnCreate.addEventListener('click', applePaySession)
  const applePayBtn = document.querySelector('apple-pay-button') as HTMLElement
  const applePayBtnWrap = document.querySelector('#applePay-wrap') as HTMLElement

  if (!applePayBtn && availableApplePay) {
    paymentBlock?.append(applePayBtnParentCreate)
    applePayBtnParentCreate?.append(applePayBtnCreate)
  } else if (applePayBtn && !availableApplePay) {
    applePayBtnWrap.remove()
  }
}

export default initApplePay
